import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { GlobalStyle } from '../styles/GlobalStyle';
import pancakeImage from './pannenkoek-vrijstaand-rond.png';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const slideIn = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
`;

const slideOut = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
`;

const PageContainer = styled.div`
  position: fixed;
  inset: 0;
  overflow: hidden;
`;

const GameContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const GameContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const SetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const SetupTitle = styled.h1`
  font-size: 2.5rem;
  color: #FF6F00;
  margin-bottom: 2rem;
  text-align: center;
`;

const NameInput = styled.input`
  padding: 15px;
  font-size: 1.2rem;
  border: 3px solid #FF6F00;
  border-radius: 15px;
  margin: 20px;
  width: 80%;
  max-width: 300px;
  text-align: center;
  background-color: #FFF3E0;
  transition: all 0.3s ease;
  font-family: 'Fredoka One', cursive;

  &:focus {
    outline: none;
    box-shadow: 0 0 10px #FF6F00;
  }
`;

const StartButton = styled.button`
  background-color: #FF6F00;
  color: white;
  font-size: 1.2rem;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Fredoka One', cursive;

  &:hover {
    background-color: #E65100;
    transform: scale(1.05);
  }
`;

const CountdownText = styled.div`
  font-size: 8rem;
  color: #FF6F00;
  animation: ${pulse} 1s infinite;
`;

const TimerBarContainer = styled.div`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30vh;
  background-color: #ddd;
  border-radius: 2px;
  overflow: hidden;
`;

const TimerBarFill = styled.div`
  width: 100%;
  height: ${props => props.timeLeft * 10}%;
  background: linear-gradient(to top, #00ff00, #ff0000);
  border-radius: 4px;
  transition: height 0.05s linear;
`;

const PancakeContainer = styled.div`
  width: 80vmin;
  height: 80vmin;
  max-width: 400px;
  max-height: 400px;
  position: relative;
  overflow: visible;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Pancake = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${pancakeImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  animation: ${props => props.isEntering ? slideIn : slideOut} 0.5s ease-in-out;
  animation-fill-mode: forwards;
`;

const Ingredient = styled.div`
  position: absolute;
  font-size: 40px; // Vergroot van 30px naar 40px
  transition: all 0.3s ease;
  transform: ${props => `translate(-50%, -50%) rotate(${props.rotation}deg)`};
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
`;

const Pantry = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  padding: 10px;
  background-color: ${props => props.error ? '#ffcccb' : '#FFCC80'};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: 5px 0;
  position: relative;
  transition: background-color 0.3s ease;
`;

const PantryIngredient = styled.div`
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 2px;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const PantryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 10px;
`;

const PlayerName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #FF6F00;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
`;

const FinalScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
`;

const FinalScore = styled.div`
  font-size: 2rem;
  color: #FF6F00;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const CelebrationEmoji = styled.span`
  font-size: 3rem;
  margin: 0 0.5rem;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Button = styled.button`
  color: white;
  font-weight: bold;
  padding: 0.8rem 1.2rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Fredoka One', cursive;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 0.5rem;
  background-color: #FF6F00;
  width: 100%;
  max-width: 250px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

const ReturnButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: right center;
  font-size: 0.8rem;
  padding: 8px 15px;
  width: auto;
  z-index: 1001;
`;

const WinnerScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: 1000;
  overflow: auto;
  padding: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ingredients = [
  '🍓', '🍌', '🫐', '🍫', '🍯', '🥜', '🍎', '🍋', '🍊', '🍇', '🍒', '🥝', 
  '🍍', '🥭', '🍑', '🍐', '🍈', '🍉', '🥥', '🍅', '🥑', '🍔', '🍕', '🌭', 
  '🥨', '🥐', '🧀', '🍦', '🍩', '🍪', '🍰', '🧁', '🥧', '🍫', '🍬', '🍭'
];

const PancakeDobbleDouble = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [pancakeIngredients, setPancakeIngredients] = useState([]);
  const [pantryIngredientsPlayer1, setPantryIngredientsPlayer1] = useState([]);
  const [pantryIngredientsPlayer2, setPantryIngredientsPlayer2] = useState([]);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEntering, setIsEntering] = useState(true);
  const [canClickPlayer1, setCanClickPlayer1] = useState(true);
  const [canClickPlayer2, setCanClickPlayer2] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState(null);
  const [ingredientPositions, setIngredientPositions] = useState([]);
  const [player1Name, setPlayer1Name] = useState('');
  const [player2Name, setPlayer2Name] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [player1Error, setPlayer1Error] = useState(false);
  const [player2Error, setPlayer2Error] = useState(false);
  const [setupStage, setSetupStage] = useState(0);
  const [countdown, setCountdown] = useState(3);
  const [player1Found, setPlayer1Found] = useState(false);
  const [player2Found, setPlayer2Found] = useState(false);
  const [pancakeRadius, setPancakeRadius] = useState(0);

  const inputRef = useRef(null);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        containerRef.current.style.width = `${window.innerWidth}px`;
        containerRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && inputRef.current) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          inputRef.current.focus();
        }, 100);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
  const updatePancakeRadius = () => {
    setPancakeRadius(Math.min(window.innerWidth, window.innerHeight) * 0.3); // Verhoog naar 0.3 voor een grotere pannenkoek
  };
  
  updatePancakeRadius();
  window.addEventListener('resize', updatePancakeRadius);
  
  return () => window.removeEventListener('resize', updatePancakeRadius);
}, []);

  const getIngredientPositions = useCallback(() => {
  const positions = [];
  const ingredientRadius = pancakeRadius * 0.15; // Vergroot van 0.1 naar 0.15
  const margin = ingredientRadius;
  
  for (let i = 0; i < 6; i++) {
    let x, y, rotation;
    do {
      const angle = Math.random() * 2 * Math.PI;
      const radius = Math.random() * (pancakeRadius - ingredientRadius - margin - 30) + 30;
      x = radius * Math.cos(angle);
      y = radius * Math.sin(angle);
      rotation = Math.random() * 360;
    } while (
      positions.some(pos => 
        Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2)) < ingredientRadius * 3
      )
    );
    positions.push({ x, y, rotation });
  }
  return positions;
}, [pancakeRadius]);

  const getRandomIngredients = (count, excludeList = []) => {
    const availableIngredients = ingredients.filter(i => !excludeList.includes(i));
    return shuffleArray(availableIngredients).slice(0, count);
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const startNewRound = useCallback(() => {
    const newPancakeIngredients = getRandomIngredients(6);
    const matchingIngredient = newPancakeIngredients[Math.floor(Math.random() * newPancakeIngredients.length)];
    const otherIngredients = getRandomIngredients(6, [...newPancakeIngredients]);
    const newPantryIngredients = [
      matchingIngredient,
      ...otherIngredients
    ];
    setPancakeIngredients(newPancakeIngredients);
    setPantryIngredientsPlayer1(shuffleArray(newPantryIngredients));
    setPantryIngredientsPlayer2(shuffleArray(newPantryIngredients));
    setTimeLeft(10);
    setIsPlaying(true);
    setIsEntering(true);
    setCanClickPlayer1(true);
    setCanClickPlayer2(true);
    setIngredientPositions(getIngredientPositions());
    setPlayer1Error(false);
    setPlayer2Error(false);
    setPlayer1Found(false);
    setPlayer2Found(false);
  }, [getIngredientPositions]);

  const handleIngredientClick = (ingredient, player) => {
    if (!isPlaying || (player === 1 && !canClickPlayer1) || (player === 2 && !canClickPlayer2)) return;

    if (pancakeIngredients.includes(ingredient)) {
      // Correct antwoord
      if (player === 1) {
        setPlayer1Found(true);
        setCanClickPlayer1(false);
      } else {
        setPlayer2Found(true);
        setCanClickPlayer2(false);
      }

      if ((player === 1 && player2Found) || (player === 2 && player1Found)) {
        // Beide spelers hebben het juiste ingrediënt gevonden
        setTimeout(() => {
          startNewRound();
        }, 1000);
      }
    } else {
      // Fout antwoord
      if (player === 1) {
        setCanClickPlayer1(false);
        setPlayer1Error(true);
        setTimeout(() => {
          setCanClickPlayer1(true);
          setPlayer1Error(false);
        }, 3000);
      } else {
        setCanClickPlayer2(false);
        setPlayer2Error(true);
        setTimeout(() => {
          setCanClickPlayer2(true);
          setPlayer2Error(false);
        }, 3000);
      }
    }
  };

  const endGame = useCallback(() => {
    setIsPlaying(false);
    setGameOver(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }, []);

  useEffect(() => {
    if (isPlaying && timeLeft <= 0) {
      // Tijd is op, controleer wie het ingrediënt niet heeft gevonden
      if (!player1Found && !player2Found) {
        setWinner("Geen");
      } else if (!player1Found) {
        setWinner(player2Name);
      } else if (!player2Found) {
        setWinner(player1Name);
      }
      endGame();
    }
  }, [timeLeft, isPlaying, player1Found, player2Found, player1Name, player2Name, endGame]);

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 0.05;
          } else {
            return 0;
          }
        });
      }, 50);

      return () => clearInterval(timer);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (gameStarted) {
      startNewRound();
    }
  }, [gameStarted, startNewRound]);

  const handleNameSubmit = (e) => {
    e.preventDefault();
    if (setupStage === 0 && player1Name) {
      setSetupStage(1);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    } else if (setupStage === 1 && player2Name) {
      setSetupStage(2);
      startCountdown();
    }
  };

  const startCountdown = () => {
    let count = 3;
    const timer = setInterval(() => {
      setCountdown(count);
      count--;
      if (count < 0) {
        clearInterval(timer);
        setGameStarted(true);
        setSetupStage(3);
      }
    }, 1000);
  };

  return (
  <>
    <GlobalStyle />
    <PageContainer>
      <GameContainer ref={containerRef}>
        <GameContent>
          {setupStage < 2 && (
            <SetupWrapper>
              <SetupTitle>
                {setupStage === 0 ? "Voer de naam van speler 1 in" : "Voer de naam van speler 2 in"}
              </SetupTitle>
              <form onSubmit={handleNameSubmit}>
                <NameInput
                  ref={inputRef}
                  type="text"
                  placeholder={setupStage === 0 ? "Naam speler 1" : "Naam speler 2"}
                  value={setupStage === 0 ? player1Name : player2Name}
                  onChange={(e) => setupStage === 0 ? setPlayer1Name(e.target.value) : setPlayer2Name(e.target.value)}
                  onBlur={() => window.scrollTo(0, 0)}
                  autoFocus
                />
                <ButtonGroup>
                  <StartButton 
                    type="button" 
                    onClick={() => setupStage === 0 ? navigate('/games') : setSetupStage(0)}
                  >
                    Terug
                  </StartButton>
                  <StartButton type="submit">Volgende</StartButton>
                </ButtonGroup>
              </form>
            </SetupWrapper>
          )}
          
          {setupStage === 2 && (
            <CountdownText>{countdown}</CountdownText>
          )}
          
          {!gameOver && setupStage === 3 && (
            <>
              <Pantry error={player2Error}>
                <PlayerName style={{ transform: 'rotate(180deg)' }}>{player2Name}</PlayerName>
                {pantryIngredientsPlayer2.map((ingredient, index) => (
                  <PantryIngredient
                    key={index}
                    onClick={() => handleIngredientClick(ingredient, 2)}
                    style={{ opacity: canClickPlayer2 ? 1 : 0.5, transform: 'rotate(180deg)' }}
                  >
                    {ingredient}
                  </PantryIngredient>
                ))}
                {player2Found && <PantryOverlay style={{ transform: 'rotate(180deg)' }}>✅</PantryOverlay>}
              </Pantry>
            
              <PancakeContainer>
                <Pancake isEntering={isEntering}>
                  {pancakeIngredients.map((ingredient, index) => (
                    <Ingredient
                      key={index}
                      x={ingredientPositions[index].x + pancakeRadius}
                      y={ingredientPositions[index].y + pancakeRadius}
                      rotation={ingredientPositions[index].rotation}
                    >
                      {ingredient}
                    </Ingredient>
                  ))}
                </Pancake>
              </PancakeContainer>
            
              <Pantry error={player1Error}>
                <PlayerName>{player1Name}</PlayerName>
                {pantryIngredientsPlayer1.map((ingredient, index) => (
                  <PantryIngredient
                    key={index}
                    onClick={() => handleIngredientClick(ingredient, 1)}
                    style={{ opacity: canClickPlayer1 ? 1 : 0.5 }}
                  >
                    {ingredient}
                  </PantryIngredient>
                ))}
                {player1Found && <PantryOverlay>✅</PantryOverlay>}
              </Pantry>
            
              <TimerBarContainer>
                <TimerBarFill timeLeft={timeLeft} />
              </TimerBarContainer>
              
              <ReturnButton onClick={() => navigate('/games')}>
                Terug naar spelletjes
              </ReturnButton>
            </>
          )}
        
          {gameOver && (
            <WinnerScreen>
              <FinalScoreWrapper>
                <FinalScore>
                  <CelebrationEmoji>🎉</CelebrationEmoji>
                  {winner === "Geen" ? "Tijd is op. Gelijkspel!" : `${winner} wint!`}
                  <CelebrationEmoji>🎉</CelebrationEmoji>
                </FinalScore>
                <ButtonContainer>
                  <Button onClick={() => {
                    setGameOver(false);
                    startNewRound();
                  }}>
                    Opnieuw spelen
                  </Button>
                  <Button onClick={() => navigate('/games')}>
                    Terug naar spelletjes
                  </Button>
                </ButtonContainer>
              </FinalScoreWrapper>
            </WinnerScreen>
          )}
           </GameContent>
        </GameContainer>
      </PageContainer>
    </>
  );
};

export default PancakeDobbleDouble;

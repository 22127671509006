import React from 'react';
import { useNavigate } from 'react-router-dom';

const Pictionary = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      textAlign: 'center',
      padding: '2rem',
      backgroundColor: '#f0f8ff',
      borderRadius: '15px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    title: {
      color: '#4a90e2',
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    text: {
      fontSize: '1.2rem',
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
    highlight: {
      fontWeight: 'bold',
      color: '#e74c3c',
    },
    comingSoon: {
      backgroundColor: '#4a90e2',
      color: 'white',
      padding: '0.5rem 1rem',
      borderRadius: '20px',
      fontWeight: 'bold',
      display: 'inline-block',
      transform: 'rotate(-5deg)',
      marginBottom: '1rem',
    },
    cta: {
      fontStyle: 'italic',
      color: '#7f8c8d',
    },
    backButton: {
      backgroundColor: '#4a90e2',
      color: 'white',
      padding: '0.5rem 1rem',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1rem',
      marginTop: '1rem',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Pictionary</h2>
      <p style={styles.text}>
        <span style={styles.highlight}>Ssst! Er wordt hier hard getekend...</span>
        <br />
        Ons Pictionary spel is bijna klaar om je tekenvaardigheden op de proef te stellen!
      </p>
      <div style={styles.comingSoon}>
        Binnenkort beschikbaar!
      </div>
      <p style={styles.cta}>
        Kom snel terug om te zien of jij de Picasso onder je vrienden bent!
      </p>
      <button style={styles.backButton} onClick={() => navigate('/games')}>
        Terug naar Games
      </button>
    </div>
  );
};

export default Pictionary;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ARPlacematWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = '/ARPlacemat.html';
  }, [navigate]);

  return null;
};

export default ARPlacematWrapper;

import React from 'react';
import styled from 'styled-components';

const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  box-sizing: border-box;
`;

const PhoneFrame = styled.div`
  width: 100%;
  max-width: 375px;
  height: 100vh;
  max-height: 812px; // iPhone X height, which has a 19.5:9 aspect ratio
  background-color: transparent;
  overflow: hidden;
  position: relative;
  aspect-ratio: 9 / 19.5;
  
  @media (min-width: 769px) {
    border-radius: 40px;
    border: 10px solid #333;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }

  @media (max-width: 768px) {
    max-width: none;
    max-height: none;
    aspect-ratio: auto;
    border: none;
    box-shadow: none;
  }
`;

const CenteredContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileFrame = ({ children }) => (
  <PhoneContainer>
    <PhoneFrame>
      <CenteredContent>{children}</CenteredContent>
    </PhoneFrame>
  </PhoneContainer>
);

export default MobileFrame;

import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import kleurplaatKnof1 from '../components/kleurplaten/kleurplaat-knof1.png';
import kleurplaatKok1 from '../components/kleurplaten/kleurplaat-kok1.png';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    touch-action: none;
  }
`;

const PageContainer = styled.div`
  position: fixed;
  inset: 0;
  overflow: hidden;
`;

const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const GameContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
`;

const CanvasContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
`;

const ControlButton = styled.button`
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const ColorPicker = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const ColorOption = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  margin: 2px;
  cursor: pointer;
`;

const BrushSizePicker = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column-reverse;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const BrushSizeOption = styled.button`
  font-size: ${props => `${props.size * 2}px`};
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const ReturnButton = styled(ControlButton)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1001;
`;

const ConfirmDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmButton = styled.button`
  margin: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: ${props => props.confirm ? '#4CAF50' : '#f44336'};
`;

const TemplateOption = styled.div`
  width: 60px;
  height: 60px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isBlank ? '#f0f0f0' : 'white'};
  overflow: hidden;
  &:hover {
    border-color: #999;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const PopupHint = styled.div`
  position: absolute;
  bottom: 120%;
  right: -20px;
  background-color: #fff;
  border: 2px solid #4CAF50;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  animation: bounce 2s infinite;
  z-index: 1001;
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 20px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #4CAF50 transparent transparent;
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-10px);}
    60% {transform: translateY(-5px);}
  }
`;

function Kleurboek() {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const templateCanvasRef = useRef(null);
  const containerRef = useRef(null);
  const [currentColor, setCurrentColor] = useState('#000000');
  const [brushSize, setBrushSize] = useState(8);
  const [isDrawing, setIsDrawing] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBrushSizePicker, setShowBrushSizePicker] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [paths, setPaths] = useState([]);
  const [currentPath, setCurrentPath] = useState(null);
  const [drawHistory, setDrawHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTemplates, setShowTemplates] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);

  const templates = [
    null, // Leeg vel
    kleurplaatKnof1,
    kleurplaatKok1
  ];

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedKleurboek');
    if (hasVisitedBefore) {
      setIsFirstVisit(false);
    } else {
      localStorage.setItem('hasVisitedKleurboek', 'true');
    }
  }, []);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        containerRef.current.style.width = `${window.innerWidth}px`;
        containerRef.current.style.height = `${window.innerHeight}px`;
      }
    };
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const updateCanvasSize = () => {
      const canvas = canvasRef.current;
      const templateCanvas = templateCanvasRef.current;
      const container = containerRef.current;
      if (canvas && templateCanvas && container) {
        const width = container.clientWidth;
        const height = container.clientHeight - 60; // Subtract control bar height
        canvas.width = width;
        canvas.height = height;
        templateCanvas.width = width;
        templateCanvas.height = height;
        redrawCanvas();
      }
    };
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      redrawCanvas();
    }
  }, [selectedTemplate]);

  const startDrawing = (e) => {
    setIsDrawing(true);
    const newPath = { color: currentColor, size: brushSize, points: [] };
    setCurrentPath(newPath);
    draw(e, newPath);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    if (currentPath && currentPath.points.length > 0) {
      const newPaths = [...paths, currentPath];
      setPaths(newPaths);
      // Update draw history
      const newHistory = drawHistory.slice(0, currentHistoryIndex + 1);
      newHistory.push(newPaths);
      setDrawHistory(newHistory);
      setCurrentHistoryIndex(newHistory.length - 1);
    }
    setCurrentPath(null);
  };

  const draw = (e, path = currentPath) => {
  if (!isDrawing) return;
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const rect = canvas.getBoundingClientRect();
  const x = (e.clientX || e.touches[0].clientX) - rect.left;
  const y = (e.clientY || e.touches[0].clientY) - rect.top;
  const newPoint = { x, y };
  const newPath = { ...path, points: [...path.points, newPoint] };
  setCurrentPath(newPath);

  ctx.lineWidth = path.size;
  ctx.lineCap = 'round';
  ctx.strokeStyle = path.color;

  if (newPath.points.length < 2) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y);
  } else {
    const lastPoint = newPath.points[newPath.points.length - 2];
    const currentPoint = newPath.points[newPath.points.length - 1];
    
    // Start a new path
    ctx.beginPath();
    
    // Move to the last point
    ctx.moveTo(lastPoint.x, lastPoint.y);
    
    // Calculate control point
    const controlX = (lastPoint.x + currentPoint.x) / 2;
    const controlY = (lastPoint.y + currentPoint.y) / 2;
    
    // Draw a quadratic curve to the current point
    ctx.quadraticCurveTo(controlX, controlY, currentPoint.x, currentPoint.y);
    
    // Stroke the path
    ctx.stroke();
  }
};

  const redrawCanvas = () => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  drawPaths();

  const templateCanvas = templateCanvasRef.current;
  const templateCtx = templateCanvas.getContext('2d');
  templateCtx.clearRect(0, 0, templateCanvas.width, templateCanvas.height);
  
  if (selectedTemplate) {
    const img = new Image();
    img.onload = () => {
      templateCtx.drawImage(img, 0, 0, templateCanvas.width, templateCanvas.height);
    };
    img.src = selectedTemplate;
  }
};

  const drawPaths = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const currentPaths = currentHistoryIndex >= 0 ? drawHistory[currentHistoryIndex] : [];
    currentPaths.forEach(path => {
      ctx.beginPath();
      ctx.lineWidth = path.size;
      ctx.strokeStyle = path.color;
      path.points.forEach((point, index) => {
        if (index === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      });
      ctx.stroke();
    });
  };

  const undo = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(currentHistoryIndex - 1);
      setPaths(drawHistory[currentHistoryIndex - 1]);
      redrawCanvas();
    } else if (currentHistoryIndex === 0) {
      setCurrentHistoryIndex(-1);
      setPaths([]);
      redrawCanvas();
    }
  };

  const handleClearCanvas = () => {
    setShowConfirmDialog(true);
  };

  const confirmClearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    setPaths([]);
    setDrawHistory([]);
    setCurrentHistoryIndex(-1);
    setSelectedTemplate(null);
    setShowConfirmDialog(false);
    
    const templateCanvas = templateCanvasRef.current;
    const templateCtx = templateCanvas.getContext('2d');
    templateCtx.clearRect(0, 0, templateCanvas.width, templateCanvas.height);
  };

  const downloadImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = canvasRef.current.width;
    canvas.height = canvasRef.current.height;
    const ctx = canvas.getContext('2d');
    
    // Draw the coloring canvas
    ctx.drawImage(canvasRef.current, 0, 0);
    
    // Draw the template canvas on top
    ctx.drawImage(templateCanvasRef.current, 0, 0);
    
    const link = document.createElement('a');
    link.download = 'mijn_kleurplaat.png';
    link.href = canvas.toDataURL();
    link.click();
  };

  const toggleColorPicker = (event) => {
    setShowColorPicker(!showColorPicker);
    setShowBrushSizePicker(false);
    setShowTemplates(false);
    event.stopPropagation();
  };

  const toggleBrushSizePicker = (event) => {
    setShowBrushSizePicker(!showBrushSizePicker);
    setShowColorPicker(false);
    setShowTemplates(false);
    event.stopPropagation();
  };

  const toggleTemplatePicker = (event) => {
    setShowTemplates(!showTemplates);
    setShowColorPicker(false);
    setShowBrushSizePicker(false);
    setIsFirstVisit(false);
    event.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowColorPicker(false);
      setShowBrushSizePicker(false);
      setShowTemplates(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
  };
}, []);

const selectTemplate = (template) => {
  setSelectedTemplate(template);
  setShowTemplates(false);
  setPaths([]);
  setDrawHistory([]);
  setCurrentHistoryIndex(-1);
  
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  const templateCanvas = templateCanvasRef.current;
  const templateCtx = templateCanvas.getContext('2d');
  templateCtx.clearRect(0, 0, templateCanvas.width, templateCanvas.height);
  
  if (template) {
    const img = new Image();
    img.onload = () => {
      templateCtx.drawImage(img, 0, 0, templateCanvas.width, templateCanvas.height);
    };
    img.src = template;
  }
};

const colors = [
  '#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF',
  '#FFFF00', '#FF00FF', '#00FFFF', '#FFA500', '#800080'
];

const brushSizes = [3, 8, 15];

return (
  <>
    <GlobalStyle />
    <PageContainer>
      <GameContainer ref={containerRef}>
        <GameContent>
          <CanvasContainer>
            <Canvas 
              ref={canvasRef}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseOut={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
            />
            <Canvas 
              ref={templateCanvasRef}
              style={{pointerEvents: 'none', position: 'absolute', top: 0, left: 0}}
            />
          </CanvasContainer>
          <Controls>
            <div style={{ position: 'relative' }}>
              <ControlButton onClick={toggleColorPicker}>🎨</ControlButton>
              <ColorPicker show={showColorPicker} onClick={(e) => e.stopPropagation()}>
                {colors.map(color => (
                  <ColorOption 
                    key={color} 
                    style={{backgroundColor: color}}
                    onClick={() => {
                      setCurrentColor(color);
                      setShowColorPicker(false);
                    }}
                  />
                ))}
              </ColorPicker>
            </div>
            <div style={{ position: 'relative' }}>
              <ControlButton onClick={toggleBrushSizePicker}>🖌️</ControlButton>
              <BrushSizePicker show={showBrushSizePicker} onClick={(e) => e.stopPropagation()}>
                {brushSizes.map(size => (
                  <BrushSizeOption 
                    key={size}
                    size={size}
                    onClick={() => {
                      setBrushSize(size);
                      setShowBrushSizePicker(false);
                    }}
                  >
                    🖌️
                  </BrushSizeOption>
                ))}
              </BrushSizePicker>
            </div>
            <ControlButton onClick={undo}>↩️</ControlButton>
            <ControlButton onClick={handleClearCanvas}>🗑️</ControlButton>
            <ControlButton onClick={downloadImage}>💾</ControlButton>
            <div style={{ position: 'relative' }}>
              <ControlButton onClick={toggleTemplatePicker}>🖼️</ControlButton>
              {isFirstVisit && (
                <PopupHint>
                  Kies hier je kleurplaat!
                </PopupHint>
              )}
              {showTemplates && (
                <div style={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  padding: '5px',
                  borderRadius: '5px',
                  zIndex: 1000,
                }}>
                  {templates.map((template, index) => (
                    <TemplateOption
                      key={index}
                      isBlank={template === null}
                      onClick={() => selectTemplate(template)}
                    >
                      {template === null ? (
                        <span style={{ fontSize: '24px' }}>➕</span>
                      ) : (
                        <img src={template} alt={`Template ${index}`} />
                      )}
                    </TemplateOption>
                  ))}
                </div>
              )}
            </div>
          </Controls>
        </GameContent>
        <ReturnButton onClick={() => navigate('/')}>🏠</ReturnButton>
      </GameContainer>
    </PageContainer>
    {showConfirmDialog && (
      <ConfirmDialog>
        <p>Weet je zeker dat je de tekening wilt wissen?</p>
        <div>
          <ConfirmButton confirm onClick={confirmClearCanvas}>Ja</ConfirmButton>
          <ConfirmButton onClick={() => setShowConfirmDialog(false)}>Nee</ConfirmButton>
        </div>
      </ConfirmDialog>
    )}
  </>
);
}

export default Kleurboek;

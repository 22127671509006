import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';
import axios from 'axios';
import pancakeImage from './pannenkoek-vrijstaand-rond.png';

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (document.activeElement.tagName === 'INPUT') {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return keyboardHeight;
};

const GlobalStyle = createGlobalStyle`
  body, button, div {
    font-family: 'Fedora', sans-serif;
  }
`;

const slideIn = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
`;

const slideOut = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
`;

const pulse = keyframes`
  0% { transform: scaleY(1); }
  50% { transform: scaleY(1.05); }
  100% { transform: scaleY(1); }
`;

const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Fedora', sans-serif;
`;

const TimerBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 40px;
`;

const TimerBarFill = styled.div`
  width: ${props => props.timeLeft * 10}%;
  height: 100%;
  background: linear-gradient(to right, #ff0000, #00ff00);
  border-radius: 10px;
  transition: width 0.05s linear;
  animation: ${pulse} ${props => 1 - props.timeLeft / 10}s infinite;
`;

const PancakeContainer = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
`;

const Pancake = styled.div`
  width: 300px;
  height: 300px;
  background-image: url(${pancakeImage});
  background-size: cover;
  background-position: center;
  position: absolute;
  animation: ${props => props.isEntering ? slideIn : slideOut} 0.5s ease-in-out;
  animation-fill-mode: forwards;
`;

const Ingredient = styled.div`
  position: absolute;
  font-size: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

const Pantry = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 10px;
  background-color: #FFCC80;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  margin-top: 40px;
`;

const PantryIngredient = styled.div`
  font-size: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

const BlurOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ErrorMessage = styled.div`
  font-size: 2rem;
  color: #FF6F00;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const FinalScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
`;

const ScoreAnimation = keyframes`
  0% { transform: scale(0.5); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const FinalScore = styled.div`
  font-size: 2rem;
  color: #FF6F00;
  animation: ${ScoreAnimation} 1s ease-out;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const CelebrationEmoji = styled.span`
  font-size: 3rem;
  margin: 0 0.5rem;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Button = styled.button`
  color: white;
  font-weight: bold;
  padding: 0.8rem 1.2rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Fedora', sans-serif;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 0.5rem;
  background-color: #FF6F00;
  width: 100%;
  max-width: 250px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

const IntroScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${props => `calc(100vh - ${props.keyboardHeight}px)`};
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Fedora', sans-serif;
  overflow-y: auto;
`;

const IntroContent = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IntroTitle = styled.h1`
  font-size: clamp(2rem, 5vw, 3rem);
  color: #FF6F00;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const IntroText = styled.p`
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: #4A4A4A;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const StartButton = styled(Button)`
  font-size: clamp(1.2rem, 4vw, 1.8rem);
  padding: 15px 30px;
  background-color: #4CAF50;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) rotate(2deg);
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
`;

const PancakeEmoji = styled.span`
  font-size: clamp(3rem, 10vw, 4rem);
  margin: 10px 0;
  animation: ${pulse} 2s infinite;
`;

const ingredients = [
  '🍓', '🍌', '🫐', '🍫', '🍯', '🥜', '🍎', '🍋', '🍊', '🍇', '🍒', '🥝', 
  '🍍', '🥭', '🍑', '🍐', '🍈', '🍉', '🥥', '🍅', '🥑', '🍔', '🍕', '🌭', 
  '🥨', '🥐', '🧀', '🍦', '🍩', '🍪', '🍰', '🧁', '🥧', '🍫', '🍬', '🍭'
];

const PancakeDobble = () => {
  const navigate = useNavigate();
  const keyboardHeight = useKeyboardHeight();
  const [pancakeIngredients, setPancakeIngredients] = useState([]);
  const [pantryIngredients, setPantryIngredients] = useState([]);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEntering, setIsEntering] = useState(true);
  const [canClick, setCanClick] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [ingredientPositions, setIngredientPositions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [highScores, setHighScores] = useState([]);
  const [playerName, setPlayerName] = useState('');

  const getIngredientPositions = useCallback(() => {
    const positions = [];
    const pancakeRadius = 110;
    const ingredientRadius = 20;
    
    for (let i = 0; i < 6; i++) {
      let newPosition;
      let attempts = 0;
      do {
        const angle = (i / 6) * 2 * Math.PI + (Math.random() * 0.5 - 0.25);
        const radius = Math.random() * (pancakeRadius - ingredientRadius - 20) + 20;
        const x = 150 + radius * Math.cos(angle);
        const y = 150 + radius * Math.sin(angle);
        newPosition = { top: `${y}px`, left: `${x}px` };
        attempts++;
        if (attempts > 100) break;
      } while (positions.some(pos => 
        Math.sqrt(Math.pow(parseInt(pos.top) - parseInt(newPosition.top), 2) + 
                  Math.pow(parseInt(pos.left) - parseInt(newPosition.left), 2)) < 70
      ));
      positions.push(newPosition);
    }
    return positions;
  }, []);

  const getRandomIngredients = (count, excludeList = []) => {
    const availableIngredients = ingredients.filter(i => !excludeList.includes(i));
    return shuffleArray(availableIngredients).slice(0, count);
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const startNewRound = useCallback(() => {
    if (gameOver) {
      setScore(0);
    }
    const newPancakeIngredients = getRandomIngredients(6);
    const matchingIngredient = newPancakeIngredients[Math.floor(Math.random() * newPancakeIngredients.length)];
    const newPantryIngredients = [
      matchingIngredient,
      ...getRandomIngredients(6, [...newPancakeIngredients])
    ];
    setPancakeIngredients(newPancakeIngredients);
    setPantryIngredients(shuffleArray(newPantryIngredients));
    setTimeLeft(10);
    setIsPlaying(true);
    setIsEntering(true);
    setCanClick(true);
    setGameOver(false);
    setIngredientPositions(getIngredientPositions());
  }, [gameOver, getIngredientPositions]);

  const handleIngredientClick = (ingredient) => {
    if (!isPlaying || !canClick) return;

    if (pancakeIngredients.includes(ingredient) && pantryIngredients.includes(ingredient)) {
      setScore(prevScore => {
        const newScore = prevScore + 1;
        console.log('Updated score:', newScore);
        return newScore;
      });
      setIsEntering(false);
      setTimeout(() => {
        setIsEntering(true);
        startNewRound();
      }, 500);
    } else {
      setShowError(true);
      setCanClick(false);
      setTimeout(() => {
        setShowError(false);
        setCanClick(true);
      }, 3000);
    }
  };

  const fetchHighScores = async () => {
    try {
      console.log('Fetching highscores...');
      const response = await axios.get('/api/pancakedobble/highscores');
      console.log('Fetched highscores:', response.data);
      setHighScores(response.data);
    } catch (error) {
      console.error('Error fetching highscores:', error);
    }
  };

  useEffect(() => {
    fetchHighScores();
  }, []);

  useEffect(() => {
    console.log('Score updated:', score);
  }, [score]);

  const endGame = useCallback(async () => {
    setIsPlaying(false);
    setGameOver(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    console.log('Game ended. Final score:', score);

    if (playerName && !gameOver) {
      try {
        console.log('Posting score to server...', { name: playerName, score });
        const response = await axios.post('/api/pancakedobble/highscores', { name: playerName, score });
        console.log('Server response:', response.data);
        await fetchHighScores();
      } catch (error) {
        console.error('Error saving score:', error);
      }
    }
  }, [score, playerName, gameOver, fetchHighScores]);

  useEffect(() => {
    if (timeLeft <= 0 && isPlaying) {
      endGame();
    }
  }, [timeLeft, isPlaying, endGame]);

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 0.05;
          } else {
            clearInterval(timer);
            endGame();
            return 0;
          }
        });
      }, 50);

      return () => clearInterval(timer);
    }
  }, [isPlaying, endGame]);

  const handleStartGame = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    
    window.scrollTo(0, 0);
    setTimeout(() => {
      setScore(0);
      setShowIntro(false);
      startNewRound();
    }, 100);
  };

  return (
    <>
      <GlobalStyle />
      {showIntro ? (
        <IntroScreen keyboardHeight={keyboardHeight}>
          <IntroContent>
            <IntroTitle>Welkom Chef!</IntroTitle>
            <PancakeEmoji>🥞</PancakeEmoji>
            <IntroText>
              Kun jij de snelste pannenkoekenbakker worden? Zoek snel het ingrediënt dat zowel op de pannenkoek als in de voorraadkast zit. Klik erop om punten te scoren, maar wees vlug want de tijd tikt!
            </IntroText>
            <input
              type="text"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              onBlur={() => window.scrollTo(0, 0)}
              placeholder="Voer je naam in"
              style={{ marginBottom: '20px', padding: '10px', fontSize: '16px' }}
            />
            <StartButton onClick={handleStartGame}>
              Start het spel!
            </StartButton>
          </IntroContent>
        </IntroScreen>
      ) : (
        <GameWrapper>
          {!gameOver && (
            <TimerBarContainer>
              <TimerBarFill timeLeft={timeLeft} />
            </TimerBarContainer>
          )}
          {!gameOver ? (
            <>
              <PancakeContainer>
                <Pancake isEntering={isEntering}>
                  {pancakeIngredients.map((ingredient, index) => (
                    <Ingredient
                      key={index}
                      style={ingredientPositions[index]}
                      onClick={() => handleIngredientClick(ingredient)}
                    >
                      {ingredient}
                    </Ingredient>
                  ))}
                </Pancake>
              </PancakeContainer>
              <Pantry>
                {pantryIngredients.map((ingredient, index) => (
                  <PantryIngredient
                    key={index}
                    onClick={() => handleIngredientClick(ingredient)}
                  >
                    {ingredient}
                  </PantryIngredient>
                ))}
              </Pantry>
              <ButtonContainer>
                <Button onClick={() => navigate('/games')}>Terug naar spelletjes</Button>
              </ButtonContainer>
            </>
          ) : (
            <FinalScoreWrapper>
              <CelebrationEmoji>🎉</CelebrationEmoji>
              <FinalScore>Geweldig gespeeld!<br/>Je eindstand is:<br/>{score} punten!</FinalScore>
              <CelebrationEmoji>🏆</CelebrationEmoji>
              <div>
                <h2>Highscores</h2>
                <ul>
                  {highScores.map((score, index) => (
                    <li key={index}>{score.name}: {score.score}</li>
                  ))}
                </ul>
              </div>
              <ButtonContainer>
                <Button onClick={() => {setScore(0); setShowIntro(true);}}>Nog een keer spelen</Button>
                <Button onClick={() => navigate('/games')}>Terug naar spelletjes</Button>
              </ButtonContainer>
            </FinalScoreWrapper>
          )}
          {showError && (
            <BlurOverlay>
              <ErrorMessage>Fout! Wacht 3 seconden...</ErrorMessage>
            </BlurOverlay>
          )}
        </GameWrapper>
      )}
    </>
  );
};

export default PancakeDobble;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: 'Fredoka One', cursive;
    background-color: white;
    overflow: hidden;
  }

  #root {
    height: 100%;
  }
`;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyle } from './styles/GlobalStyle';
import MobileFrame from './components/MobileFrame';
import Stars from './components/Stars';
import Logo from './components/Logo';
import Mascot from './components/Mascot';
import Menu from './components/Menu';
import Games from './games/Games';
import ConnectFourOptions from './games/ConnectFourOptions';
import ConnectFourLocal from './games/ConnectFourLocal';
import ConnectFourVsKnof from './games/ConnectFourVsKnof';
import ConnectFourOnline from './games/ConnectFourOnline';
import PancakeDobble from './games/PancakeDobble';
import PancakeDobbleMenu from './games/PancakeDobbleMenu';
import PancakeDobbleDouble from './games/PancakeDobbleDouble';
import Pictionary from './games/Pictionary';
import PratenMetKnof from './components/PratenMetKnof';
import Kleurboek from './components/Kleurboek';
import ARPlacematWrapper from './components/ARPlacematWrapper';

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  overflow-y: auto;
  position: relative;

  @media (max-width: 414px) {
    padding: 0.5rem;
  }
`;

const StarsBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
`;

const ContentOverlay = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const RouteContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

function Home({ navigateToPage }) {
  console.log('Home component is rendering');
  return (
    <>
      <Logo />
      <Mascot currentPage="/" />
      <Menu navigateToPage={navigateToPage} />
    </>
  );
}

function AppContent() {
  const navigate = useNavigate();

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(async (registration) => {
        try {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BAs1wg6lkqY2OzH07DY-LdV3_wgiEYgGbWkKnT86qayQVcU1Pn9_lfLXDUZu1Izr6jT0wwPUYvXmoeuJp231xYY'
          });

          // Stuur de subscription naar je server
          await fetch('https://push.kidshospitality.nl/api/save-subscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              subscription: subscription,
              username: 'rheezerbelten' // of een andere manier om de gebruikersnaam te krijgen
            }),
          });

          console.log('Push notification subscription successful');
        } catch (error) {
          console.error('Error subscribing to push notifications:', error);
        }
      });
    }
  }, []);

  const navigateToPage = (page) => {
    navigate(page);
  };

  return (
    <MobileFrame>
      <ContentWrapper>
        <StarsBackground>
          <Stars />
        </StarsBackground>
        <ContentOverlay>
          <RouteContentWrapper>
            <Routes>
              <Route path="/" element={<Home navigateToPage={navigateToPage} />} />
              <Route path="/games" element={<Games navigateToPage={navigateToPage} />} />
              <Route path="/games/connect-four" element={<ConnectFourOptions />} />
              <Route path="/games/connect-four/local" element={<ConnectFourLocal />} />
              <Route path="/games/connect-four/vs-knof" element={<ConnectFourVsKnof />} />
              <Route path="/games/connect-four/online" element={<ConnectFourOnline />} />
              <Route path="/games/pancake-dobble" element={<PancakeDobbleMenu />} />
              <Route path="/games/pancake-dobble/single" element={<PancakeDobble />} />
              <Route path="/games/pancake-dobble/double" element={<PancakeDobbleDouble />} />
              <Route path="/games/pictionary" element={<Pictionary />} />
              <Route path="/praten-met-knof" element={<PratenMetKnof />} />
              <Route path="/kleurboek" element={<Kleurboek />} />
              <Route path="/placemat" element={<ARPlacematWrapper />} />
            </Routes>
          </RouteContentWrapper>
        </ContentOverlay>
      </ContentWrapper>
    </MobileFrame>
  );
}

function App() {
  console.log('App component is rendering');
  return (
    <Router>
      <GlobalStyle />
      <AppContent />
    </Router>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import logoImage from '../components/logo-rheezerbelten-wit.png';

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const LogoImage = styled.img`
  width: 80%;
  max-width: 200px;
  height: auto;
  object-fit: contain;
`;

const Logo = () => {
  return (
    <LogoContainer>
      <LogoImage 
        src={logoImage} 
        alt="Rheezerbelten Logo" 
      />
    </LogoContainer>
  );
};

export default Logo;

import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: 'Fredoka One', cursive;
  background-color: #55A54A;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 15px;
  cursor: pointer;
  z-index: 1001;
`;

const ChatbotWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

const ChatbotIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

function PratenMetKnof() {
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <BackButton onClick={() => navigate('/')}>Terug</BackButton>
      <ChatbotWrapper>
        <ChatbotIframe
          ref={iframeRef}
          src="https://knofdepad.funiversum.nl?aid=759b9c2f-c45b-495c-a2df-c8cf1fecb2c8&lang=nl"
          allow="microphone"
          title="Knof de Pad Chatbot"
        />
      </ChatbotWrapper>
    </Container>
  );
}

export default PratenMetKnof;
